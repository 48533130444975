import React from "react";
import { graphql } from "gatsby";
import _ from "lodash";

import { Layout } from "../components";

const TermsOfService = ({ data }) => {
  return (
    <Layout
      className="templated-page"
      pageData={_.get(data, "contentfulPageData")}
    />
  );
};

export default TermsOfService;

export const query = graphql`
  query termsOfServiceQuery {
    contentfulPageData(title: { eq: "Terms of Service" }) {
      title
      description
      image {
        file {
          url
        }
      }
      header
      bodyText {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
